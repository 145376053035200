import { IconConfigFragment } from '@codegen/cmsUtils';
import { BadgeVariant } from '@ui-v2/core/Badge/Badge';
import CoreNotification, { Props } from '@ui-v2/core/Notification/Notification';
import useGetIcon from '@web/context/hooks/useGetIcon';

export const getNotificationIcon = (
  variant: BadgeVariant,
): keyof IconConfigFragment => `${variant}Icon`;

const Notification = ({ variant = 'information', ...props }: Props) => {
  const getIcon = useGetIcon();
  const iconIdentifier = getNotificationIcon(variant);

  const resolvedIcon = getIcon(iconIdentifier);

  return <CoreNotification icon={resolvedIcon} {...props} />;
};

export default Notification;
